import React from 'react';
import styled from 'styled-components';

function Home() {
    return (
        <Container>
            <h1>Vision</h1>
            <p>This project aims to establish a platform providing informations and methodologies about the remaining carbon budgets of each country. Given a reference date and the target warming level, the global remaining carbon budget, i.e., the remaining carbon budget for the whole world, is uniquely computable by pure physics. It can be calculated with specified levels of uncertainty. In contrast, there is no unique way to distribute the remaining carbon budget of the world among the countries. This project shall enrich the scientific discussion about different methodologies to compute the remaining carbon budget per country and to compare their consequences on basis of the most current data publicly available.</p>
            <p>The project is initiated by <b>South Westphalia University of Applied Sciences, Hagen</b>. Its development is organized on <a href="https://carbon-budget.atlassian.net/jira/projects" target='_blank'>Jira</a>.</p>
            <br />
            <h1>Background</h1>
            <p>One of the most urgent, but also difficult, problems of humankind is to avoid emissions of CO₂ and other greenhouse gases. As one method of monitoring the degree of fulfillment of this goal the IPCC¹ has introduced the remaining carbon budget. It is defined as “the total net amount of CO₂ that could be released in the future by human activities while keeping global warming to a specific global warming level, such as 1.5°C, taking into account the warming contribution from non-CO₂ forcers as well.”¹ In 2015, the <a href="https://unfccc.int/process-and-meetings/the-paris-agreement" target='_blank'>Paris Agreement</a> has been committed by 196 countries to hold this level well below 2°C, and to pursue efforts to limit the temperature increase to 1.5°C at the UN Climate Change Conference (COP21) in Paris, France, on 12 December 2015. It entered into force on 4 November 2016. The Paris Agreement works on a five-year cycle of increasingly ambitious climate action -- or, ratcheting up -- carried out by countries. Since 2020, countries have been submitting their national climate action plans, known as nationally determined contributions (NDCs - countries communicate actions they will take to reduce their greenhouse gas emissions in order to reach the goals of the Paris Agreement). The information gathered through the enhanced transparency framework (ETF) will feed into the Global stocktake which will assess the collective progress towards the long-term climate goals.</p>
            <p>In the <a href="https://www.ipcc.ch/report/ar6/wg1/" target='_blank'>6th Assessment Report</a> of the Intergovernmental Panel on Climate Change (IPCC) the remaining carbon budgets of the world by 1 January 2020 have been computed for various target warming levels.</p>
            <br />
            <h1>References</h1>
            <p>1. More precisely, the likelihood of p % is the pth percentile of transient climate response to cumulative CO₂ emissions (TCRE), i.e., the ratio of the globally averaged surface temperature change per unit CO₂ emitted. For a specific amount of cumulative CO₂ emissions, a known global temperature change can be expected only within a range of uncertainty, or in other words, by a percentile. For details see IPCC (2021): AR 6 Climate Change 2021. The Physical Science Basis. Cambridge New York: Cambridge University Press. pp. 39, 758</p>
            <br />
            <h1>Websites for monitoring and forecasting carbon budgets</h1>
            <p>
                <a href="https://www.climatewatchdata.org/" target='_blank'>https://www.climatewatchdata.org/</a>
                <br />
                <a href="https://climateactiontracker.org/" target='_blank'>https://climateactiontracker.org/</a>
                <br />
                <a href="https://ourworldindata.org/co2-and-greenhouse-gas-emissions" target='_blank'>https://ourworldindata.org/co2-and-greenhouse-gas-emissions</a>
                <br />
                <a href="http://www.globalcarbonatlas.org/" target='_blank'>http://www.globalcarbonatlas.org/</a>
                <br />
                <a href="https://www.realclimate.org/index.php/archives/2019/08/how-much-co2-your-country-can-still-emit-in-three-simple-steps/" target='_blank'>https://www.realclimate.org/index.php/archives/2019/08/how-much-co2-your-country-can-still-emit-in-three-simple-steps/</a>
            </p>
        </Container>
    )
}

const Container = styled.div`
    h1 {
        font-size: 2rem;
    }
    p {
        font-size: 1.2rem;
        padding-top: 1rem;
        line-height: 1.7rem;
    }
`

export default Home