export const menuItems = [
    {
        id: 1,
        title: 'Carbon Budget',
        icon: '',
        link: '/'
    },
    {
        id: 2,
        title: 'Global Carbon Budget',
        icon: '',
        link: '/map'
    },
    {
        id: 3,
        title: 'Country Carbon Budget',
        icon: '',
        link: '/dashboard'
    }
]