import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from "chart.js/auto";

function Charte() {

    const allData = [
        { "year": "1990", "co": { "elucf": "21284.04", "ilucf": "23054.21", "energy": "20790.42"} },
        { "year": "1991", "co": { "elucf": "21440.49", "ilucf": "23210.66", "energy": "20933.02"} },
        { "year": "1992", "co": { "elucf": "21390.04", "ilucf": "23160.21", "energy": "20861.52"} },
        { "year": "1993", "co": { "elucf": "21531.83", "ilucf": "23302.43", "energy": "20980.54"} },
        { "year": "1994", "co": { "elucf": "21676.89", "ilucf": "23447.16", "energy": "21088.49"} },
        { "year": "1995", "co": { "elucf": "22299.2", "ilucf": "24075.08", "energy": "21677.02"} },
        { "year": "1996", "co": { "elucf": "22778.98", "ilucf": "24415.14", "energy": "22143.95"} },
        { "year": "1997", "co": { "elucf": "23202.62", "ilucf": "25528.62", "energy": "22541.85"} },
        { "year": "1998", "co": { "elucf": "23365.55", "ilucf": "25135.56", "energy": "22707.25"} },
        { "year": "1999", "co": { "elucf": "23530.42", "ilucf": "25204.1", "energy": "22839.94"} },
        { "year": "2000", "co": { "elucf": "24280.27", "ilucf": "25901.81", "energy": "23558.81"} },
        { "year": "2001", "co": { "elucf": "24644.44", "ilucf": "25988.6", "energy": "23890.57"} },
        { "year": "2002", "co": { "elucf": "24989.51", "ilucf": "26706.93", "energy": "24207.65"} },
        { "year": "2003", "co": { "elucf": "26133.42", "ilucf": "27579.09", "energy": "25273.42"} },
        { "year": "2004", "co": { "elucf": "27331.85", "ilucf": "29050.2", "energy": "26433.31"} },
        { "year": "2005", "co": { "elucf": "28371.82", "ilucf": "29884.64", "energy": "27407.97"} },
        { "year": "2006", "co": { "elucf": "29308.18", "ilucf": "31114.92", "energy": "28256.89"} },
        { "year": "2007", "co": { "elucf": "30418.99", "ilucf": "31798.65", "energy": "29289.88"} },
        { "year": "2008", "co": { "elucf": "30632.3", "ilucf": "32001.8", "energy": "29489.01"} },
        { "year": "2009", "co": { "elucf": "30238.05", "ilucf": "31910.11", "energy": "29062.65"} },
        { "year": "2010", "co": { "elucf": "32095.87", "ilucf": "33478.09", "energy": "30841.05"} },
        { "year": "2011", "co": { "elucf": "33079.72", "ilucf": "33386.21", "energy": "31730.59"} },
        { "year": "2012", "co": { "elucf": "33460.09", "ilucf": "33776.66", "energy": "32076"} },
        { "year": "2013", "co": { "elucf": "34119.89", "ilucf": "34418.74", "energy": "32677.11"} },
        { "year": "2014", "co": { "elucf": "34261.37", "ilucf": "34824.6", "energy": "32762"} },
        { "year": "2015", "co": { "elucf": "34070.18", "ilucf": "34657.09", "energy": "32625.81"} },
        { "year": "2016", "co": { "elucf": "34145.65", "ilucf": "35327.54", "energy": "32662.89"} },
        { "year": "2017", "co": { "elucf": "34687.84", "ilucf": "35836.11", "energy": "33183.46"} },
        { "year": "2018", "co": { "elucf": "35560.56", "ilucf": "36855.48", "energy": "33994.02"} },
        { "year": "2019", "co": { "elucf": "35477.25", "ilucf": "36948.97", "energy": "33860.53"} },
        { "year": "2020", "co": { "elucf": "33566.43", "ilucf": "34739.12", "energy": "31933.79"} },
    ]

    const data = {
        datasets: [{
            label: 'Total excluding LUCF',
            data: allData,
            parsing: {
                xAxisKey: 'year',
                yAxisKey: 'co.elucf'
            }
        },
        {
            label: 'Total including LUCF',
            data: allData,
            parsing: {
                xAxisKey: 'year',
                yAxisKey: 'co.ilucf'
            }
        },
        {
            label: 'Energy',
            data: allData,
            parsing: {
                xAxisKey: 'year',
                yAxisKey: 'co.energy'
            }
        }
        ]
    }

    return (
        <div>
            <Line data={data} options={{
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: "Net CO₂ emissions per year (MtCO₂)"
                    },
                    legend: {
                        display: true
                    }
                }
            }} />
        </div>
    )
}

export default Charte