import React from 'react';
import { styled } from 'styled-components';
import { menuItems } from '../utils/menuItems';

function Navigation({active, setActive}) {

  return (
    <NavStyled>
        <ul className="menu-items">
            {menuItems.map((item) => {
                return <li key={item.id} onClick={() => setActive(item.id)} className={active === item.id ? 'active' : ''}>{item.title}</li>
            })}
        </ul>
    </NavStyled>
  )
}

const NavStyled = styled.nav`
    z-index: 1;
    padding: 1rem 1rem;
    width: 260px;
    height: 100%;
    background: white;
    border: 3px solid #ffffff;
    backdrop-filter: blue(4.5px);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    .menu-items {
        flex: 1;
        display: flex;
        flex-direction: column;
        li {
            display: flex;
            grid-template-columns: 40px auto;
            align-items: center;
            margin: .6rem 0;
            font-weight: 500;
            cursor: pointer;
            transition: all .4s ease-in-out;
            color: rgba(34, 34, 96, .6);
            padding-left: 1rem;
            font-size: 1.3rem;
            position: relative;
        }
    }
    .active {
        color: rgba(34, 34, 96, 1) !important;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background: #222260;
            border-radius: 0 10px 10px 0;
        }   
    }
`;

export default Navigation