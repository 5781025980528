import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { InnerLayout } from '../styles/Layouts'
import { Chart as ChartJs, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
)

function Dashboard() {

  const [START_YEAR, setSTART_YEAR] = useState(2020)
  const [STOP_YEAR, setSTOP_YEAR] = useState(2060)
  const [START_EMISSION, setSTART_EMISSION] = useState(40)
  const [aspectRatio, setaspectRatio] = useState()
  const [emissionPointRadius, setemissionPointRadius] = useState(5)
  const [emissionPointHitRadius, setemissionPointHitRadius] = useState(8)
  const [lineTension, setlineTension] = useState(0)

  useEffect(() => {
    calculateAllCountry()
  }, [])

  function changeCountry(element) {
    const country = element;
    applyCountry(country);
  }

  function applyCountry(country) {
    document.getElementById("country-linear-country").value = country;
    calculateAllCountry();
  }

  function calculateAllCountry() {
    equitableBudget();
    showYearsEmissionsCountryLinear();
  }

  const CountryPopulation = {
    Afghanistan: 38928346,
    Albania: 2877797,
    Algeria: 43851044,
    Andorra: 77265,
    Angola: 32866272,
    "Antigua and Barbuda": 97929,
    Argentina: 45195774,
    Armenia: 2963243,
    Australia: 25499884,
    Austria: 9006398,
    Azerbaijan: 10139177,
    Bahamas: 393244,
    Bahrain: 1701575,
    Bangladesh: 164689383,
    Barbados: 287375,
    Belarus: 9449323,
    Belgium: 11589623,
    Belize: 397628,
    Benin: 12123200,
    Bhutan: 771608,
    Bolivia: 11673021,
    "Bosnia and Herzegovina": 3280819,
    Botswana: 2351627,
    Brazil: 212559417,
    Brunei: 437479,
    Bulgaria: 6948445,
    "Burkina Faso": 20903273,
    Burundi: 11890784,
    "Cote d'Ivoire": 26378274,
    "Cabo Verde": 555987,
    Cambodia: 16718965,
    Cameroon: 26545863,
    Canada: 37742154,
    "Central African Republic": 4829767,
    Chad: 16425864,
    Chile: 19116201,
    China: 1439323776,
    Colombia: 50882891,
    Comoros: 869601,
    "Congo (Congo-Brazzaville)": 5518087,
    "Costa Rica": 5094118,
    Croatia: 4105267,
    Cuba: 11326616,
    Cyprus: 1207359,
    "Czechia (Czech Republic)": 10708981,
    "Democratic Republic of the Congo": 89561403,
    Denmark: 5792202,
    Djibouti: 988000,
    Dominica: 71986,
    "Dominican Republic": 10847910,
    Ecuador: 17643054,
    Egypt: 102334404,
    "El Salvador": 6486205,
    "Equatorial Guinea": 1402985,
    Eritrea: 3546421,
    Estonia: 1326535,
    "Eswatini (fmr. Swaziland)": 1160164,
    Ethiopia: 114963588,
    Fiji: 896445,
    Finland: 5540720,
    France: 65273511,
    Gabon: 2225734,
    Gambia: 2416668,
    Georgia: 3989167,
    Germany: 83783942,
    Ghana: 31072940,
    Greece: 10423054,
    Grenada: 112523,
    Guatemala: 17915568,
    Guinea: 13132795,
    "Guinea-Bissau": 1968001,
    Guyana: 786552,
    Haiti: 11402528,
    Honduras: 9904607,
    Hungary: 9660351,
    Iceland: 341243,
    India: 1380004385,
    Indonesia: 273523615,
    Iran: 83992949,
    Iraq: 40222493,
    Ireland: 4937786,
    Israel: 8655535,
    Italy: 60461826,
    Jamaica: 2961167,
    Japan: 126476461,
    Jordan: 10203134,
    Kazakhstan: 18776707,
    Kenya: 53771296,
    Kiribati: 119449,
    Kuwait: 4270571,
    Kyrgyzstan: 6524195,
    Laos: 7275560,
    Latvia: 1886198,
    Lebanon: 6825445,
    Lesotho: 2142249,
    Liberia: 5057681,
    Libya: 6871292,
    Liechtenstein: 38128,
    Lithuania: 2722289,
    Luxembourg: 625978,
    Madagascar: 27691018,
    Malawi: 19129952,
    Malaysia: 32365999,
    Maldives: 540544,
    Mali: 20250833,
    Malta: 441543,
    "Marshall Islands": 59190,
    Mauritania: 4649658,
    Mauritius: 1271768,
    Mexico: 128932753,
    Micronesia: 548914,
    Moldova: 4033963,
    Mongolia: 3278290,
    Montenegro: 628066,
    Morocco: 36910560,
    Mozambique: 31255435,
    "Myanmar (formerly Burma)": 54409800,
    Namibia: 2540905,
    Nauru: 10824,
    Nepal: 29136808,
    Netherlands: 17134872,
    "New Zealand": 4822233,
    Nicaragua: 6624554,
    Niger: 24206644,
    Nigeria: 206139589,
    "North Korea": 25778816,
    "North Macedonia": 2083374,
    Norway: 5421241,
    Oman: 5106626,
    Pakistan: 220892340,
    Palau: 18094,
    "Palestine State": 5101414,
    Panama: 4314767,
    "Papua New Guinea": 8947024,
    Paraguay: 7132538,
    Peru: 32971854,
    Philippines: 109581078,
    Poland: 37846611,
    Portugal: 10196709,
    Qatar: 2881053,
    Romania: 19237691,
    Russia: 145934462,
    Rwanda: 12952218,
    "Saint Kitts and Nevis": 53199,
    "Saint Lucia": 183627,
    "Saint Vincent and the Grenadines": 110940,
    Samoa: 198414,
    "Sao Tome and Principe": 219159,
    "Saudi Arabia": 34813871,
    Senegal: 16743927,
    Serbia: 8737371,
    Seychelles: 98347,
    "Sierra Leone": 7976983,
    Singapore: 5850342,
    Slovakia: 5459642,
    Slovenia: 2078938,
    "Solomon Islands": 686884,
    Somalia: 15893222,
    "South Africa": 59308690,
    "South Korea": 51269185,
    "South Sudan": 11193725,
    Spain: 46754778,
    "Sri Lanka": 21413249,
    Sudan: 43849260,
    Suriname: 586632,
    Sweden: 10449381,
    Switzerland: 8654622,
    Syria: 17500658,
    Tajikistan: 9537645,
    Tanzania: 59734218,
    Thailand: 69799978,
    "Timor-Leste": 1318445,
    Togo: 8278724,
    Tonga: 105695,
    "Trinidad and Tobago": 1399488,
    Tunisia: 11818619,
    Turkey: 84339067,
    Turkmenistan: 6031200,
    Tuvalu: 11792,
    Uganda: 45741007,
    Ukraine: 43733762,
    "United Arab Emirates": 9890402,
    "United Kingdom": 67886011,
    "United States of America": 331002651,
    Uruguay: 3473730,
    Uzbekistan: 33469203,
    Vanuatu: 307145,
    Venezuela: 28435940,
    Vietnam: 97338579,
    Yemen: 29825964,
    Zambia: 18383955,
    Zimbabwe: 14862924
  };

  const CountryCarbonEmission = {
    Afghanistan: 3.315,
    Albania: 1.327,
    Algeria: 45.481,
    Andorra: 0.137,
    Angola: 6.41,
    Anguilla: 0.038,
    "Antigua and Barbuda": 0.133,
    Argentina: 45.733,
    Armenia: 1.509,
    Aruba: 0.232,
    Australia: 113.132,
    Austria: 18.549,
    Azerbaijan: 10.569,
    Bahamas: 0.72,
    Bahrain: 9.399,
    Bangladesh: 25.714,
    Barbados: 0.332,
    Belarus: 16.655,
    Belgium: 27.223,
    Belize: 0.18,
    Benin: 1.996,
    Bermuda: 0.191,
    Bhutan: 0.526,
    "Bonaire, Saint Eustatius and Saba": 0.093,
    "Bosnia and Herzegovina": 6.781,
    Botswana: 1.89,
    Brazil: 132.585,
    "British Virgin Islands": 0.043,
    Brunei: 2.718,
    Bulgaria: 11.524,
    "Burkina Faso": 1.183,
    Burundi: 0.192,
    Cambodia: 4.184,
    Canada: 158.949,
    "Cabo Verde": 0.164,
    "Central African Republic": 0.057,
    Chad: 0.274,
    Chile: 23.507,
    China: 2862.988,
    Colombia: 24.678,
    Comoros: 0.083,
    "Congo (Congo-Brazzaville)": 0.898,
    "Cook Islands": 0.022,
    "Costa Rica": 2.407,
    "Cote d'Ivoire": 2.86,
    Croatia: 4.852,
    Cuba: 6.148,
    Curaçao: 1.026,
    Cyprus: 2.003,
    "Czechia (Czech Republic)": 27.514,
    "North Korea": 8.018,
    "Democratic Republic of the Congo": 0.734,
    Denmark: 8.433,
    Djibouti: 0.11,
    Dominica: 0.043,
    "Dominican Republic": 7.511,
    Ecuador: 10.032,
    Egypt: 61.649,
    "El Salvador": 1.87,
    "Equatorial Guinea": 2.803,
    Eritrea: 0.228,
    Estonia: 3.379,
    Ethiopia: 4.468,
    "Faeroe Islands": 0.201,
    Micronesia: 0.04,
    Fiji: 0.38,
    Finland: 11.612,
    France: 86.226,
    "French Guiana": 0.199,
    "French Polynesia": 0.226,
    Gabon: 1.225,
    Gambia: 0.149,
    Georgia: 2.86,
    Germany: 194.167,
    Ghana: 4.617,
    Greece: 17.941,
    Greenland: 0.151,
    Grenada: 0.091,
    Guadeloupe: 0.699,
    Guatemala: 5.986,
    Guinea: 1.014,
    "Guinea-Bissau": 0.086,
    Guyana: 0.679,
    Haiti: 0.898,
    Honduras: 2.945,
    "Hong Kong": 11.405,
    Hungary: 13.395,
    Iceland: 0.969,
    India: 716.694,
    Indonesia: 180.293,
    Iraq: 60.86,
    Ireland: 10.173,
    Iran: 200.154,
    Israel: 17.029,
    Italy: 92.733,
    Jamaica: 2.196,
    Japan: 301.837,
    Jordan: 7.026,
    Kazakhstan: 80.75,
    Kenya: 5.007,
    Kiribati: 0.019,
    Kosovo: 2.484,
    Kuwait: 26.379,
    Kyrgyzstan: 3.007,
    Laos: 9.261,
    Latvia: 2.087,
    Lebanon: 7.276,
    Lesotho: 0.633,
    Liberia: 0.298,
    Libya: 14.877,
    Liechtenstein: 0.041,
    Lithuania: 3.8,
    Luxembourg: 2.662,
    Macao: 0.343,
    "North Macedonia": 2.176,
    Madagascar: 1.105,
    Malawi: 0.434,
    Malaysia: 76.053,
    Maldives: 0.49,
    Mali: 1.007,
    Malta: 0.456,
    "Marshall Islands": 0.041,
    Martinique: 0.641,
    Mauritania: 1.009,
    Mauritius: 1.195,
    Mayotte: 0.083,
    Mexico: 118.863,
    Mongolia: 24.206,
    Montenegro: 0.722,
    Montserrat: 0.008,
    Morocco: 18.618,
    Mozambique: 1.989,
    "Myanmar (formerly Burma)": 9.838,
    Namibia: 1.114,
    Nauru: 0.015,
    Nepal: 4.631,
    Netherlands: 41.921,
    "New Caledonia": 2.376,
    "New Zealand": 10.233,
    Nicaragua: 1.548,
    Niger: 0.506,
    Nigeria: 35.509,
    Niue: 0.003,
    Norway: 11.699,
    "Palestine State": 0.815,
    Oman: 16.582,
    Pakistan: 63.943,
    Palau: 0.06,
    Panama: 3.355,
    "Papua New Guinea": 1.806,
    Paraguay: 2.327,
    Peru: 15.005,
    Philippines: 40.015,
    Bolivia: 6.509,
    Poland: 87.206,
    Portugal: 12.999,
    Qatar: 31.396,
    Cameroon: 2.019,
    "South Korea": 176.863,
    Moldova: 1.407,
    "South Sudan": 0.381,
    Sudan: 6.013,
    Réunion: 1.252,
    Romania: 20.427,
    Russia: 458.365,
    Rwanda: 0.324,
    "Saint Helena": 0.003,
    "Saint Lucia": 0.136,
    "Sint Maarten (Dutch part)": 0.192,
    Samoa: 0.067,
    "Sao Tome and Principe": 0.034,
    "Saudi Arabia": 169.872,
    Senegal: 3.075,
    Serbia: 13.466,
    Seychelles: 0.159,
    "Sierra Leone": 0.262,
    Singapore: 12.474,
    Slovakia: 9.218,
    Slovenia: 3.823,
    "Solomon Islands": 0.081,
    Somalia: 0.182,
    "South Africa": 129.892,
    Spain: 68.64,
    "Sri Lanka": 6.23,
    "Saint Kitts and Nevis": 0.065,
    "Saint Pierre and Miquelon": 0.016,
    "Saint Vincent and the Grenadines": 0.064,
    Suriname: 0.688,
    "Eswatini (fmr. Swaziland)": 0.276,
    Sweden: 11.173,
    Switzerland: 10.028,
    Syria: 8.463,
    Taiwan: 75.145,
    Tajikistan: 2.356,
    Thailand: 74.133,
    "Timor-Leste": 0.143,
    Togo: 0.629,
    Tonga: 0.039,
    "Trinidad and Tobago": 11.02,
    Tunisia: 8.191,
    Turkey: 108.992,
    Turkmenistan: 20.829,
    "Turks and Caicos Islands": 0.062,
    Tuvalu: 0.002,
    Uganda: 1.561,
    Ukraine: 60.748,
    "United Arab Emirates": 42.081,
    "United Kingdom": 100.712,
    Tanzania: 3.287,
    "United States of America": 1434.448,
    Uruguay: 1.797,
    Uzbekistan: 31.168,
    Vanuatu: 0.049,
    Venezuela: 27.068,
    Vietnam: 71.046,
    "Wallis and Futuna Islands": 0.007,
    Yemen: 2.735,
    Zambia: 1.923,
    Zimbabwe: 2.988
  };

  function getPopulationForCountry(country) {
    return CountryPopulation[country];
  }

  function countryFossilCementBudgetFromPageSettings(prefix) {
    const budgetGt = budgetFromPageSettings(prefix);
    const country = document.getElementById(`${prefix}-country`).value;
    const countryPopulation = getPopulationForCountry(country);
    const perPersonBudgetT = budgetGt / 7.9; // divided by 7.9 billion, multiplied by 1 billion to go from Gt to t
    const countryBudgetM = (perPersonBudgetT * countryPopulation) / 1000000; // divided by 1M to go from t to Mt
    return countryBudgetM * 0.9; // * 36 (world carbon & cement emissions) / 40 (world total emissions)
  }

  function countryEmissionsFromPageSettings(prefix) {
    const country = document.getElementById(`${prefix}-country`).value;
    return getEmissionForCountry(country) * 3.664;
  }

  function getEmissionForCountry(country) {
    return CountryCarbonEmission[country];
  }

  function getEmissionsPerYearConstantUntilYear(emission, untilYear) {
    const startYear = START_YEAR;
    const nrOfYears = untilYear - startYear;
    const budget = emission * nrOfYears;
    return getEmissionsPerYearConstantEmissions(emission, budget);
  }

  function roundToTwoDigits(num) {
    if (num > 1) {
      return Math.round((num + 0.0000001) * 100) / 100;
    } else if (num > 0.1) {
      return Math.round((num + 0.0000001) * 1000) / 1000;
    } else if (num > 0.01) {
      return Math.round((num + 0.0000001) * 10000) / 10000;
    } else {
      return Math.round((num + 0.0000001) * 100000) / 100000;
    }
  }

  class EmissionScenario {
    constructor(budget) {
      this._budget = budget;
      this._yearlyEmissions = {};
      this._showAlert = false;
    }

    addEmissionForYear(year, value) {
      this._yearlyEmissions[year] = value;
    }

    getEmissionForYear(year) {
      return this._yearlyEmissions[year];
    }

    // All emission until year (excluding year)
    getTotalEmissionUpToYear(year) {
      let total = 0;
      for (let y = START_YEAR; y < year; y++) {
        total += this.getEmissionForYear(y);
      }
      return total;
    }

    getBudgetLeftForYear(year) {
      return this.budget - this.getTotalEmissionUpToYear(year);
    }

    set actionYear(year) {
      this._actionYear = year;
    }

    get actionYear() {
      return this._actionYear;
    }

    get yearsUntilBudgetIsZero() {
      return this.yearWhenBudgetIsZero - START_YEAR;
    }

    get yearWhenBudgetIsZero() {
      for (let y = START_YEAR; y < START_YEAR + this.numberOfYears; y++) {
        if (roundToTwoDigits(this.getBudgetLeftForYear(y)) <= 0) {
          return y;
        }
      }
      return START_YEAR + this.numberOfYears;
    }

    get numberOfYears() {
      return Object.keys(this._yearlyEmissions).length;
    }

    get totalEmissions() {
      return Object.values(this._yearlyEmissions).reduce((a, b) => a + b);
    }

    get budget() {
      return this._budget;
    }

    set budget(budget) {
      this._budget = budget;
    }

    set percentDecrease(percentDecrease) {
      this._percentDecrease = percentDecrease;
    }

    get percentDecrease() {
      return this._percentDecrease;
    }

    set yearlyReduction(yearlyReduction) {
      this._yearlyReduction = yearlyReduction;
    }

    get yearlyReduction() {
      return this._yearlyReduction;
    }

    get chartYears() {
      const allYears = Object.keys(this._yearlyEmissions).map(x => Number(x));
      const startYear = Math.min(...allYears);
      const stopYear = STOP_YEAR;
      let years = [];
      for (let y = startYear; y <= stopYear; y++) {
        years.push(y);
      }
      return years;
    }

    get chartEmissionValues() {
      return this.chartYears
        .map(k => this.getEmissionForYear(k))
        .map(v => roundToTwoDigits(v));
    }

    get chartBudgetLeftValues() {
      return this.chartYears
        .map(k => this.getBudgetLeftForYear(k))
        .map(v => roundToTwoDigits(v));
    }

    get yearlyEmissions() {
      return this._yearlyEmissions;
    }

    set yearlyEmissions(yearlyEmissions) {
      this._yearlyEmissions = yearlyEmissions;
    }

    set showAlert(showAlert) {
      this._showAlert = showAlert;
    }

    get showAlert() {
      return this._showAlert;
    }
  }

  function getEmissionsPerYear(
    startEmission,
    budget,
    changeFunction,
    startYear = START_YEAR,
    endWithRemainingBudget = false
  ) {
    let remainingBudget = budget;
    const emissionsPerYear = new EmissionScenario(budget);
    let year = startYear;
    let yearlyEmission = startEmission;

    // Limit the calculations to 2518 (500 years)
    const maxIterations = 2518 - startYear;
    for (let i = 0; i < maxIterations; i++) {
      if (
        (yearlyEmission == startEmission && yearlyEmission >= remainingBudget) ||
        yearlyEmission > remainingBudget + 0.0000000001 ||
        yearlyEmission < startEmission / 100
      ) {
        emissionsPerYear.addEmissionForYear(
          year,
          endWithRemainingBudget ? remainingBudget : yearlyEmission
        );
        break;
      }

      emissionsPerYear.addEmissionForYear(year, yearlyEmission);
      remainingBudget = remainingBudget - yearlyEmission;
      year++;
      yearlyEmission = changeFunction(yearlyEmission);

      // We don't want negative emissions, since that won't make the budget run out.
      if (yearlyEmission < 0) {
        yearlyEmission = 0;
      }
    }
    return emissionsPerYear;
  }

  function getEmissionsPerYearConstantEmissions(emission, budget) {
    return getEmissionsPerYear(
      emission,
      budget,
      yearlyEmission => yearlyEmission
    );
  }

  function showYearsEmissionsCountryLinear() {
    equitableBudget();
    const delayUntil = Number(
      document.getElementById("country-linear-delay-until").value
    );
    let budget = countryFossilCementBudgetFromPageSettings("country-linear");
    const startEmission = countryEmissionsFromPageSettings("country-linear");

    const emissionScenario1 = getEmissionsPerYearConstantUntilYear(
      startEmission,
      delayUntil - 1
    );
    budget = budget - emissionScenario1.budget;
    let emissionScenario = emissionScenario1;
    if (budget > 0) {
      const emissionScenario2 = getEmissionsPerYearReduceLinear(
        startEmission,
        budget,
        delayUntil - 1
      );
      emissionScenario = mergeEmissionScenarios(
        emissionScenario1,
        emissionScenario2
      );
    } else {
      emissionScenario.yearlyReduction = "-";
      emissionScenario.budget = countryFossilCementBudgetFromPageSettings(
        "country-linear"
      );
    }

    emissionScenario.actionYear = delayUntil;

    outputForCountryEmissionScenario("country-linear", emissionScenario);
  }

  function showAlertIfBadScenario(prefix, emissionScenario) {
    if (
      emissionScenario.percentDecrease == "-" ||
      emissionScenario.yearlyReduction == "-" ||
      emissionScenario.showAlert
    ) {
      document.getElementById(`${prefix}-alert`).hidden = false;
    } else {
      document.getElementById(`${prefix}-alert`).hidden = true;
    }
  }

  function updateTextForCountryEmissionScenario(prefix, emissionScenario) {
    document.getElementById(
      `${prefix}-budget`
    ).innerHTML = `⚫ Country Carbon Budget: <b>${formatToTwoDigits(
      emissionScenario.budget
    )}</b> MtCO<sub>2</sub>`;
    document.getElementById(
      `${prefix}-total`
    ).innerHTML = `⚫ Total emissions: <b>${formatToTwoDigits(
      emissionScenario.totalEmissions
    )}</b> MtCO<sub>2</sub>`;
    if (!emissionScenario.percentDecrease) {
      document.getElementById(
        `${prefix}-end-year`
      ).innerHTML = `Given the above conditions, the budget will run out by: <b>${emissionScenario.yearWhenBudgetIsZero}</b>`;
    }

    if (emissionScenario.yearlyReduction) {
      document.getElementById(
        `${prefix}-yearly-decrease`
      ).innerHTML = `⚫ If no action to reduce the CO<sub>2</sub> emission is taken till ${emissionScenario.actionYear
      }, then amount of reduction of CO<sub>2</sub> emission needed per year is <b>${formatToTwoDigits(
        emissionScenario.yearlyReduction
      )}</b> MtCO<sub>2</sub> (${formatToTwoDigits(
        (emissionScenario.yearlyReduction /
          emissionScenario.getEmissionForYear(START_YEAR)) *
        100
      )}% of 2019 emission levels)`;
    }

    if (emissionScenario.percentDecrease) {
      document.getElementById(
        `${prefix}-percent`
      ).innerHTML = `CO<sub>2</sub> emission decrease needed per year from ${emissionScenario.actionYear
      }: <b>${formatToTwoDigits(emissionScenario.percentDecrease)}</b>%`;
    }
  }

  function chartEmissionScenario(prefix, emissionScenario, tonnePrefix) {

    let holder = document.getElementById(`${prefix}-chart-holder`);
    holder.innerHTML = `<canvas id="${prefix}-chart"></canvas>`;
    let ctx = document.getElementById(`${prefix}-chart`);

    if (document.body.clientWidth < 576) {
      setaspectRatio(1.5);
    } else if (document.body.clientWidth < 768) {
      setaspectRatio(2);
    } else if (document.body.clientWidth < 992) {
      setaspectRatio(2.5);
    } else {
      setaspectRatio(3);
    }

    try {
      new ChartJs(ctx, {
        type: "bar",
        data: {
          labels: emissionScenario.chartYears,
          datasets: [
            {
              label: `Net emissions per year (${tonnePrefix}tCO₂)`,
              backgroundColor: "lightblue",
              borderColor: "green",
              data: emissionScenario.chartEmissionValues,
              fill: false,
              pointRadius: emissionPointRadius,
              pointHoverRadius: emissionPointRadius,
              pointHitRadius: emissionPointHitRadius,
              lineTension: lineTension,
              yAxisID: "y-axis-1"
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: aspectRatio,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0
                },
                position: "left",
                scaleLabel: {
                  display: false,
                  labelString: "Emissions"
                },
                id: "y-axis-1"
              }
            ]
          }
        }
      });
    } catch (e) {
      document.getElementById(`${prefix}-chart-holder`).innerHTML =
        "<b>An error occurred when trying to show the graph of emissions.</b>";
    }
  }

  function outputForCountryEmissionScenario(prefix, emissionScenario) {
    updateTextForCountryEmissionScenario(prefix, emissionScenario);
    chartEmissionScenario(prefix, emissionScenario, "M");
    showAlertIfBadScenario(prefix, emissionScenario);
  }

  function mergeEmissionScenarios(scenario1, scenario2) {
    const mergedEmissionScenario = new EmissionScenario(
      scenario1.budget + scenario2.budget
    );
    if (scenario1.percentDecrease) {
      mergedEmissionScenario.percentDecrease = scenario1.percentDecrease;
    }
    if (scenario2.percentDecrease) {
      mergedEmissionScenario.percentDecrease = scenario2.percentDecrease;
    }
    if (scenario1.yearlyReduction) {
      mergedEmissionScenario.yearlyReduction = scenario1.yearlyReduction;
    }
    if (scenario2.yearlyReduction) {
      mergedEmissionScenario.yearlyReduction = scenario2.yearlyReduction;
    }
    const mergedYearlyEmissions = Object.assign(
      {},
      scenario1.yearlyEmissions,
      scenario2.yearlyEmissions
    );
    mergedEmissionScenario.yearlyEmissions = mergedYearlyEmissions;
    return mergedEmissionScenario;
  }

  function getEmissionsPerYearReduceLinear(
    startEmission,
    budget,
    startYear = START_YEAR
  ) {
    const expectedYears = (budget / startEmission) * 2;
    const expectedYearlyReduction = startEmission / (expectedYears - 1);
    const changeFunction = yearlyEmission =>
      yearlyEmission - expectedYearlyReduction;
    const emissionsPerYear = getEmissionsPerYear(
      startEmission,
      budget,
      changeFunction,
      startYear,
      true
    );
    // No emissions the second year = fail
    if (!emissionsPerYear.getEmissionForYear(startYear + 1)) {
      emissionsPerYear.yearlyReduction = "-";
    } else {
      emissionsPerYear.yearlyReduction = Math.abs(
        emissionsPerYear.getEmissionForYear(startYear + 1) -
        emissionsPerYear.getEmissionForYear(startYear)
      );
    }
    return emissionsPerYear;
  }

  function formatToTwoDigits(num) {
    if (isNaN(num) || num == "-") {
      return "-";
    }
    if (num > 1) {
      return num.toLocaleString("en-US", { maximumFractionDigits: 2 });
    } else if (num > 0.1) {
      return num.toLocaleString("en-US", { maximumFractionDigits: 3 });
    } else if (num > 0.01) {
      return num.toLocaleString("en-US", { maximumFractionDigits: 4 });
    } else {
      return num.toLocaleString("en-US", { maximumFractionDigits: 5 });
    }
  }

  function budgetFromPageSettings(prefix) {
    const warming = document.getElementById(`${prefix}-warming`).value;
    const probability = document.getElementById(`${prefix}-probability`).value;
    return getBudget(warming, probability);
  }

  function getBudget(warming, probability) {
    switch (warming) {
      case "1.3":
        switch (probability) {
          case "17":
            return 400;
          case "33":
            return 250;
          case "50":
            return 150;
          case "67":
            return 100;
          case "83":
            return 50;
          default:
            return -1;
        }
      case "1.4":
        switch (probability) {
          case "17":
            return 650;
          case "33":
            return 450;
          case "50":
            return 350;
          case "67":
            return 250;
          case "83":
            return 200;
          default:
            return -1;
        }
      case "1.5":
        switch (probability) {
          case "17":
            return 900;
          case "33":
            return 650;
          case "50":
            return 500;
          case "67":
            return 400;
          case "83":
            return 300;
          default:
            return -1;
        }
      case "1.6":
        switch (probability) {
          case "17":
            return 1200;
          case "33":
            return 850;
          case "50":
            return 650;
          case "67":
            return 550;
          case "83":
            return 400;
          default:
            return -1;
        }
      case "1.7":
        switch (probability) {
          case "17":
            return 1450;
          case "33":
            return 1050;
          case "50":
            return 850;
          case "67":
            return 700;
          case "83":
            return 550;
          default:
            return -1;
        }
      case "1.8":
        switch (probability) {
          case "17":
            return 1750;
          case "33":
            return 1250;
          case "50":
            return 1000;
          case "67":
            return 850;
          case "83":
            return 650;
          default:
            return -1;
        }
      case "1.9":
        switch (probability) {
          case "17":
            return 2000;
          case "33":
            return 1450;
          case "50":
            return 1200;
          case "67":
            return 1000;
          case "83":
            return 800;
          default:
            return -1;
        }
      case "2.0":
        switch (probability) {
          case "17":
            return 2300;
          case "33":
            return 1700;
          case "50":
            return 1350;
          case "67":
            return 1150;
          case "83":
            return 900;
          default:
            return -1;
        }
      case "2.1":
        switch (probability) {
          case "17":
            return 2550;
          case "33":
            return 1900;
          case "50":
            return 1500;
          case "67":
            return 1250;
          case "83":
            return 1050;
          default:
            return -1;
        }
      case "2.2":
        switch (probability) {
          case "17":
            return 2850;
          case "33":
            return 2100;
          case "50":
            return 1700;
          case "67":
            return 1400;
          case "83":
            return 1150;
          default:
            return -1;
        }
      case "2.3":
        switch (probability) {
          case "17":
            return 3100;
          case "33":
            return 2300;
          case "50":
            return 1850;
          case "67":
            return 1550;
          case "83":
            return 1250;
          default:
            return -1;
        }
      case "2.4":
        switch (probability) {
          case "17":
            return 3350;
          case "33":
            return 2500;
          case "50":
            return 2050;
          case "67":
            return 1700;
          case "83":
            return 1400;
          default:
            return -1;
        }
      default:
        return -1;
    }
  }

  function equitableBudget() {
    const budgetGt = budgetFromPageSettings("country-linear");
    const fossilBudgetGt = budgetGt * 0.9; // * 36 (world carbon & cement emissions) / 40 (world total emissions)
    const country = document.getElementById("country-linear-country").value;
    const countryPopulation = getPopulationForCountry(country);
    const perPersonFossilBudgetT = fossilBudgetGt / 7.9; // divided by 7.9 billion, multiplied by 1 billion to go from Gt to t
    const countryFossilBudgetM =
      (perPersonFossilBudgetT * countryPopulation) / 1000000; // divided by 1M to go from t to Mt
    const countryEmissionM = getEmissionForCountry(country) * 3.664; // Transform C to CO2

    document.getElementById(
      "country-equitable-global-budget"
    ).innerHTML = `<p>Global Carbon Budget</p></br><p className='blue'><b>${budgetGt}GtCO<sub>2</sub></b></p>`;
    document.getElementById(
      "country-equitable-global-fossil-budget"
    ).innerHTML = `<p>Global Fossil Carbon Budget</p></br><p className='blue'><b>${fossilBudgetGt} GtCO<sub>2</sub></b></p>`;
    document.getElementById(
      "country-equitable-country-population"
    ).innerHTML = `<p>Country population</p></br><p className='blue'><b>${formatToTwoDigits(
      countryPopulation
    )}</b></p>`;
    document.getElementById(
      "country-equitable-per-person-fossil-budget"
    ).innerHTML = `<p>Per Person Fossil Carbon Budget</p></br><p className='blue'><b>${formatToTwoDigits(
      perPersonFossilBudgetT
    )} GtCO<sub>2</sub></b></p>`;
    let countryPrefix = "M";
    let countryBudget = countryFossilBudgetM;
    if (countryBudget > 1000) {
      countryBudget = countryBudget / 1000;
      countryPrefix = "G";
    }
    document.getElementById(
      "country-equitable-country-fossil-budget"
    ).innerHTML = `<p>Fossil Country Carbon Budget</p></br><p className='blue'><b>${formatToTwoDigits(
      countryBudget
    )} ${countryPrefix}tCO<sub>2</sub></b></p>`;
    countryPrefix = "M";
    let countryEmission = countryEmissionM;
    if (countryEmission > 1000) {
      countryEmission = countryEmission / 1000;
      countryPrefix = "G";
    }
    document.getElementById(
      "country-equitable-country-emissions"
    ).innerHTML = `<p>Country Carbon Emissions</p></br><p className='blue'><b>${formatToTwoDigits(
      countryEmission
    )} ${countryPrefix}tCO<sub>2</sub></b></p>`;
  }

  return (
    <DashboardStyled>
      <InnerLayout>
        <h1>Country Carbon Budget</h1>
        <br />
        <p>Now that we have a Global Carbon Budget, how to divide it among nations. There are many questions to needs to be answered to resolve this and some of are at what point in time do you divide the budget, should developing countries get more budget than developed countries, should cold countries get more budget than warm countries. We definitely cannot answer this in simple terms but we have approached the problem in the most simpler way of dividing the budget based on the population of the country. That is to divide the global carbon budget by the global population and then multiply that with the population of a particular country and the result gives the remaining carbon budget for that particular country.</p>
        <br />
        <p>
          <label>
            Country &nbsp;
            <select id="country-linear-country" onChange={(e) => changeCountry(e.target.value)} defaultValue={'Germany'}>
              <option value=""></option>
              <option value="Afghanistan">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
              <option value="Botswana">Botswana</option>
              <option value="Brazil">Brazil</option>
              <option value="Brunei">Brunei</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cote d'Ivoire">Cote d'Ivoire</option>
              <option value="Cabo Verde">Cabo Verde</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Central African Republic">Central African Republic</option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czechia (Czech Republic)">Czechia (Czech Republic)</option>
              <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Eswatini (fmr. Swaziland)">Eswatini (fmr. Swaziland)</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Greece">Greece</option>
              <option value="Grenada">Grenada</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-Bissau">Guinea-Bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Honduras">Honduras</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran">Iran</option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Laos">Laos</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libya">Libya</option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia">Micronesia</option>
              <option value="Moldova">Moldova</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar (formerly Burma)">Myanmar (formerly Burma)</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="North Korea">North Korea</option>
              <option value="North Macedonia">North Macedonia</option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestine State">Palestine State</option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Qatar">Qatar</option>
              <option value="Romania">Romania</option>
              <option value="Russia">Russia</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
              <option value="Samoa">Samoa</option>
              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Korea">South Korea</option>
              <option value="South Sudan">South Sudan</option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syria">Syria</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania">Tanzania</option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-Leste">Timor-Leste</option>
              <option value="Togo">Togo</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States of America">United States of America</option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Vietnam">Vietnam</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </label><br /><br />
          <label>
            Global Warming Level &nbsp;
            <select id="country-linear-warming" onChange={showYearsEmissionsCountryLinear} defaultValue={'1.5'}>
              <option value="1.3">1.3ºC</option>
              <option value="1.4">1.4ºC</option>
              <option value="1.5">1.5ºC</option>
              <option value="1.6">1.6ºC</option>
              <option value="1.7">1.7ºC</option>
              <option value="1.8">1.8ºC</option>
              <option value="1.9">1.9ºC</option>
              <option value="2.0">2.0ºC</option>
              <option value="2.1">2.1ºC</option>
              <option value="2.2">2.2ºC</option>
              <option value="2.3">2.3ºC</option>
              <option value="2.4">2.4ºC</option>
            </select>
          </label> <br /> <br />
          <label>
            Probability &nbsp;
            <select id="country-linear-probability" onChange={showYearsEmissionsCountryLinear} defaultValue={'67'}>
              <option value="17">17</option>
              <option value="33">33</option>
              <option value="50">50</option>
              <option value="67">67</option>
              <option value="83">83</option>
            </select>
          </label> <br /> <br />
          <label>
            No action is taken until the year &nbsp;
            <input type="number" className="four-digit-number-input" id="country-linear-delay-until" min="2021" max="2100"
              defaultValue="2021" onChange={showYearsEmissionsCountryLinear} />
          </label>
        </p> <br />
        <div className='boxes'>
          <div id="country-equitable-global-budget" className='box'>a</div>
          <div id="country-equitable-global-fossil-budget" className='box'>b</div>
          <div id="country-equitable-per-person-fossil-budget" className='box'>c</div>
        </div>
        <div id="country-linear-alert" className="alert alert-danger" role="alert" hidden>
          <b>Note:</b> This scenario can't tell what the warming will likely be, only when the budget runs
          out, since net zero emissions are not reached before the budget runs out.
        </div>
        <div className='boxes'>
          <div id="country-equitable-country-population" className='box'>d</div>
          <div id="country-equitable-country-fossil-budget" className='box'>e</div>
          <div id="country-equitable-country-emissions" className='box'>f</div>
        </div>
        <div id="country-linear-chart-holder"></div>
        <br />
        <br />
        <h2>Results</h2>
        <br />
        <p>
          <span id="country-linear-yearly-decrease"></span>.<br /> 
          <span id="country-linear-budget"></span>.<br />
          <span id="country-linear-total"></span>. <br /> <br />
          <div className="special">
            <span id="country-linear-end-year"></span>.
          </div>
        </p>
      </InnerLayout>
    </DashboardStyled >
  )
}

const DashboardStyled = styled.div`
  .boxes {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  .blue {
    color: lightblue;
  }
  .box {
    text-align: center;
    box-shadow: 10px 5px 5px lightblue;
    width: 300px;
    height: 100px;
    padding: 10px;
    background-color: white;
    border: 1px solid lightblue;
  }
  p {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  select, input {
    padding: 0.2rem;
    width: 150px;
    font-size: 1.1rem;
    border: thin solid lightblue;
    border-radius: 4px;
  }
  #country-linear-country {
    width: 250px;
  }
  .special {
    background-color: orangered;
    text-align: center;
    padding: 0.3rem;
    font-size: 1.6rem;
  }
`

export default Dashboard