import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { InnerLayout } from './../styles/Layouts'
import Charte from './Charte'
import { Chart as ChartJs } from 'chart.js'

function Global() {

  const [START_YEAR, setSTART_YEAR] = useState(2020)
  const [STOP_YEAR, setSTOP_YEAR] = useState(2060)
  const [START_EMISSION, setSTART_EMISSION] = useState(70)
  const [aspectRatio, setaspectRatio] = useState()
  const [emissionPointRadius, setemissionPointRadius] = useState(5)
  const [emissionPointHitRadius, setemissionPointHitRadius] = useState(8)
  const [lineTension, setlineTension] = useState(0)

  function calculateAll() {
    showYearsEmissionsLaterLinear();
  }

  useEffect(() => {
    calculateAll()
  }, [])

  function budgetFromPageSettings(prefix) {
    const warming = document.getElementById(`${prefix}-warming`).value;
    const probability = document.getElementById(`${prefix}-probability`).value;
    return getBudget(warming, probability);
  }

  function getBudget(warming, probability) {
    switch (warming) {
      case "1.3":
        switch (probability) {
          case "17":
            return 400;
          case "33":
            return 250;
          case "50":
            return 150;
          case "67":
            return 100;
          case "83":
            return 50;
          default:
            return -1;
        }
      case "1.4":
        switch (probability) {
          case "17":
            return 650;
          case "33":
            return 450;
          case "50":
            return 350;
          case "67":
            return 250;
          case "83":
            return 200;
          default:
            return -1;
        }
      case "1.5":
        switch (probability) {
          case "17":
            return 900;
          case "33":
            return 650;
          case "50":
            return 500;
          case "67":
            return 400;
          case "83":
            return 300;
          default:
            return -1;
        }
      case "1.6":
        switch (probability) {
          case "17":
            return 1200;
          case "33":
            return 850;
          case "50":
            return 650;
          case "67":
            return 550;
          case "83":
            return 400;
          default:
            return -1;
        }
      case "1.7":
        switch (probability) {
          case "17":
            return 1450;
          case "33":
            return 1050;
          case "50":
            return 850;
          case "67":
            return 700;
          case "83":
            return 550;
          default:
            return -1;
        }
      case "1.8":
        switch (probability) {
          case "17":
            return 1750;
          case "33":
            return 1250;
          case "50":
            return 1000;
          case "67":
            return 850;
          case "83":
            return 650;
          default:
            return -1;
        }
      case "1.9":
        switch (probability) {
          case "17":
            return 2000;
          case "33":
            return 1450;
          case "50":
            return 1200;
          case "67":
            return 1000;
          case "83":
            return 800;
          default:
            return -1;
        }
      case "2.0":
        switch (probability) {
          case "17":
            return 2300;
          case "33":
            return 1700;
          case "50":
            return 1350;
          case "67":
            return 1150;
          case "83":
            return 900;
          default:
            return -1;
        }
      case "2.1":
        switch (probability) {
          case "17":
            return 2550;
          case "33":
            return 1900;
          case "50":
            return 1500;
          case "67":
            return 1250;
          case "83":
            return 1050;
          default:
            return -1;
        }
      case "2.2":
        switch (probability) {
          case "17":
            return 2850;
          case "33":
            return 2100;
          case "50":
            return 1700;
          case "67":
            return 1400;
          case "83":
            return 1150;
          default:
            return -1;
        }
      case "2.3":
        switch (probability) {
          case "17":
            return 3100;
          case "33":
            return 2300;
          case "50":
            return 1850;
          case "67":
            return 1550;
          case "83":
            return 1250;
          default:
            return -1;
        }
      case "2.4":
        switch (probability) {
          case "17":
            return 3350;
          case "33":
            return 2500;
          case "50":
            return 2050;
          case "67":
            return 1700;
          case "83":
            return 1400;
          default:
            return -1;
        }
      default:
        return -1;
    }
  }

  function getEmissionsPerYearConstantUntilYear(emission, untilYear) {
    const startYear = START_YEAR;
    const nrOfYears = untilYear - startYear;
    const budget = emission * nrOfYears;
    return getEmissionsPerYearConstantEmissions(emission, budget);
  }

  function getEmissionsPerYearReduceLinear(
    startEmission,
    budget,
    startYear = START_YEAR
  ) {
    const expectedYears = (budget / startEmission) * 2;
    const expectedYearlyReduction = startEmission / (expectedYears - 1);
    const changeFunction = yearlyEmission =>
      yearlyEmission - expectedYearlyReduction;
    const emissionsPerYear = getEmissionsPerYear(
      startEmission,
      budget,
      changeFunction,
      startYear,
      true
    );

    if (!emissionsPerYear.getEmissionForYear(startYear + 1)) {
      emissionsPerYear.yearlyReduction = "-";
    } else {
      emissionsPerYear.yearlyReduction = Math.abs(
        emissionsPerYear.getEmissionForYear(startYear + 1) -
        emissionsPerYear.getEmissionForYear(startYear)
      );
    }
    return emissionsPerYear;
  }

  function roundToTwoDigits(num) {
    if (num > 1) {
      return Math.round((num + 0.0000001) * 100) / 100;
    } else if (num > 0.1) {
      return Math.round((num + 0.0000001) * 1000) / 1000;
    } else if (num > 0.01) {
      return Math.round((num + 0.0000001) * 10000) / 10000;
    } else {
      return Math.round((num + 0.0000001) * 100000) / 100000;
    }
  }

  class EmissionScenario {
    constructor(budget) {
      this._budget = budget;
      this._yearlyEmissions = {};
      this._showAlert = false;
    }

    addEmissionForYear(year, value) {
      this._yearlyEmissions[year] = value;
    }

    getEmissionForYear(year) {
      return this._yearlyEmissions[year];
    }

    getTotalEmissionUpToYear(year) {
      let total = 0;
      for (let y = START_YEAR; y < year; y++) {
        total += this.getEmissionForYear(y);
      }
      return total;
    }

    getBudgetLeftForYear(year) {
      return this.budget - this.getTotalEmissionUpToYear(year);
    }

    set actionYear(year) {
      this._actionYear = year;
    }

    get actionYear() {
      return this._actionYear;
    }

    get yearsUntilBudgetIsZero() {
      return this.yearWhenBudgetIsZero - START_YEAR;
    }

    get yearWhenBudgetIsZero() {
      for (let y = START_YEAR; y < START_YEAR + this.numberOfYears; y++) {
        if (roundToTwoDigits(this.getBudgetLeftForYear(y)) <= 0) {
          return y;
        }
      }
      return START_YEAR + this.numberOfYears;
    }

    get numberOfYears() {
      return Object.keys(this._yearlyEmissions).length;
    }

    get totalEmissions() {
      return Object.values(this._yearlyEmissions).reduce((a, b) => a + b);
    }

    get budget() {
      return this._budget;
    }

    set budget(budget) {
      this._budget = budget;
    }

    set percentDecrease(percentDecrease) {
      this._percentDecrease = percentDecrease;
    }

    get percentDecrease() {
      return this._percentDecrease;
    }

    set yearlyReduction(yearlyReduction) {
      this._yearlyReduction = yearlyReduction;
    }

    get yearlyReduction() {
      return this._yearlyReduction;
    }

    get chartYears() {
      const allYears = Object.keys(this._yearlyEmissions).map(x => Number(x));
      const startYear = Math.min(...allYears);
      const stopYear = STOP_YEAR;
      let years = [];
      for (let y = startYear; y <= stopYear; y++) {
        years.push(y);
      }
      return years;
    }

    get chartEmissionValues() {
      return this.chartYears
        .map(k => this.getEmissionForYear(k))
        .map(v => roundToTwoDigits(v));
    }

    get chartBudgetLeftValues() {
      return this.chartYears
        .map(k => this.getBudgetLeftForYear(k))
        .map(v => roundToTwoDigits(v));
    }

    get yearlyEmissions() {
      return this._yearlyEmissions;
    }

    set yearlyEmissions(yearlyEmissions) {
      this._yearlyEmissions = yearlyEmissions;
    }

    set showAlert(showAlert) {
      this._showAlert = showAlert;
    }

    get showAlert() {
      return this._showAlert;
    }
  }

  function getEmissionsPerYear(
    startEmission,
    budget,
    changeFunction,
    startYear = START_YEAR,
    endWithRemainingBudget = false
  ) {
    let remainingBudget = budget;
    const emissionsPerYear = new EmissionScenario(budget);
    let year = startYear;
    let yearlyEmission = startEmission;

    const maxIterations = 2518 - startYear;
    for (let i = 0; i < maxIterations; i++) {
      if (
        (yearlyEmission == startEmission && yearlyEmission >= remainingBudget) ||
        yearlyEmission > remainingBudget + 0.0000000001 ||
        yearlyEmission < startEmission / 100
      ) {
        emissionsPerYear.addEmissionForYear(
          year,
          endWithRemainingBudget ? remainingBudget : yearlyEmission
        );
        break;
      }

      emissionsPerYear.addEmissionForYear(year, yearlyEmission);
      remainingBudget = remainingBudget - yearlyEmission;
      year++;
      yearlyEmission = changeFunction(yearlyEmission);

      if (yearlyEmission < 0) {
        yearlyEmission = 0;
      }
    }
    return emissionsPerYear;
  }

  function getEmissionsPerYearConstantEmissions(emission, budget) {
    return getEmissionsPerYear(
      emission,
      budget,
      yearlyEmission => yearlyEmission
    );
  }

  function mergeEmissionScenarios(scenario1, scenario2) {
    const mergedEmissionScenario = new EmissionScenario(
      scenario1.budget + scenario2.budget
    );
    if (scenario1.percentDecrease) {
      mergedEmissionScenario.percentDecrease = scenario1.percentDecrease;
    }
    if (scenario2.percentDecrease) {
      mergedEmissionScenario.percentDecrease = scenario2.percentDecrease;
    }
    if (scenario1.yearlyReduction) {
      mergedEmissionScenario.yearlyReduction = scenario1.yearlyReduction;
    }
    if (scenario2.yearlyReduction) {
      mergedEmissionScenario.yearlyReduction = scenario2.yearlyReduction;
    }
    const mergedYearlyEmissions = Object.assign(
      {},
      scenario1.yearlyEmissions,
      scenario2.yearlyEmissions
    );
    mergedEmissionScenario.yearlyEmissions = mergedYearlyEmissions;
    return mergedEmissionScenario;
  }

  function showYearsEmissionsLaterLinear() {
    const delayUntil = Number(
      document.getElementById("later-linear-delay-until").value
    );
    let budget = budgetFromPageSettings("later-linear");
    const startEmission = START_EMISSION;

    const emissionScenario1 = getEmissionsPerYearConstantUntilYear(
      startEmission,
      delayUntil - 1
    );
    budget = budget - emissionScenario1.budget;
    let emissionScenario = emissionScenario1;
    if (budget > 0) {
      const emissionScenario2 = getEmissionsPerYearReduceLinear(
        startEmission,
        budget,
        delayUntil - 1
      );
      emissionScenario = mergeEmissionScenarios(
        emissionScenario1,
        emissionScenario2
      );
    } else {
      emissionScenario.yearlyReduction = "-";
      emissionScenario.budget = budgetFromPageSettings("later-linear");
    }

    emissionScenario.actionYear = delayUntil;

    outputForEmissionScenario("later-linear", emissionScenario);
  }

  function outputForEmissionScenario(prefix, emissionScenario) {
    updateTextForEmissionScenario(prefix, emissionScenario);
    chartEmissionScenario(prefix, emissionScenario, "G");
  }

  function formatToTwoDigits(num) {
    if (isNaN(num) || num == "-") {
      return "-";
    }
    if (num > 1) {
      return num.toLocaleString("en-US", { maximumFractionDigits: 2 });
    } else if (num > 0.1) {
      return num.toLocaleString("en-US", { maximumFractionDigits: 3 });
    } else if (num > 0.01) {
      return num.toLocaleString("en-US", { maximumFractionDigits: 4 });
    } else {
      return num.toLocaleString("en-US", { maximumFractionDigits: 5 });
    }
  }

  function updateTextForEmissionScenario(prefix, emissionScenario) {
    document.getElementById(
      `${prefix}-budget`
    ).innerHTML = `⚫ Global Carbon Budget: <b>${formatToTwoDigits(
      emissionScenario.budget
    )}</b> GtCO<sub>2</sub>`;
    document.getElementById(
      `${prefix}-total`
    ).innerHTML = `⚫ Total emissions: <b>${formatToTwoDigits(
      emissionScenario.totalEmissions
    )}</b> GtCO<sub>2</sub>`;
    if (!emissionScenario.percentDecrease) {
      document.getElementById(
        `${prefix}-end-year`
      ).innerHTML = `Given the above conditions, the budget will run out by: <b>${emissionScenario.yearWhenBudgetIsZero}</b>`;
    }

    if (emissionScenario.yearlyReduction) {
      document.getElementById(
        `${prefix}-yearly-decrease`
      ).innerHTML = `⚫ If no action to reduce the CO<sub>2</sub> emission is taken till ${emissionScenario.actionYear
      }, then amount of reduction of CO<sub>2</sub> emission needed per year is <b>${formatToTwoDigits(
        emissionScenario.yearlyReduction
      )}</b> GtCO<sub>2</sub> (${formatToTwoDigits(
        (emissionScenario.yearlyReduction / START_EMISSION) * 100
      )}% of 2019 emission levels)`;
    }

    if (emissionScenario.percentDecrease) {
      document.getElementById(
        `${prefix}-percent`
      ).innerHTML = `CO<sub>2</sub> emission decrease needed per year from ${emissionScenario.actionYear
      }: <b>${formatToTwoDigits(emissionScenario.percentDecrease)}</b>%`;
    }
  }

  function chartEmissionScenario(prefix, emissionScenario, tonnePrefix) {

    let holder = document.getElementById(`${prefix}-chart-holder`);
    holder.innerHTML = `<canvas id="${prefix}-chart"></canvas>`;
    let ctx = document.getElementById(`${prefix}-chart`);

    if (document.body.clientWidth < 576) {
      setaspectRatio(1.5);
    } else if (document.body.clientWidth < 768) {
      setaspectRatio(2);
    } else if (document.body.clientWidth < 992) {
      setaspectRatio(2.5);
    } else {
      setaspectRatio(3);
    }

    try {
      new ChartJs(ctx, {
        type: "bar",
        data: {
          labels: emissionScenario.chartYears,
          datasets: [
            {
              label: `Net emissions per year (${tonnePrefix}tCO₂)`,
              backgroundColor: "lightblue",
              borderColor: "green",
              data: emissionScenario.chartEmissionValues,
              fill: false,
              pointRadius: emissionPointRadius,
              pointHoverRadius: emissionPointRadius,
              pointHitRadius: emissionPointHitRadius,
              lineTension: lineTension,
              yAxisID: "y-axis-1"
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: aspectRatio,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0
                },
                position: "left",
                scaleLabel: {
                  display: false,
                  labelString: "Emissions"
                },
                id: "y-axis-1"
              }
            ]
          }
        }
      });
    } catch (e) {
      document.getElementById(`${prefix}-chart-holder`).innerHTML =
        "<b>An error occurred when trying to show the graph of emissions.</b>";
    }
  }

  return (
    <DashboardStyled>
      <InnerLayout>
        <h1>Global CO₂ Emission Chart</h1>
        <br />
        <div className="stats-con">
          <div className="chart-con">
            <p>Global CO₂ emissions refer to the total amount of carbon dioxide released into the atmosphere due to human activities, such as burning fossil fuels (coal, oil, and natural gas), deforestation, and LUCF.</p>
            <br />
            <p><b>Land-Use Change and Forestry (LUCF)</b> refer to the human-driven alterations in land use patterns and the management of forests and other vegetation, which can have significant impacts on the Earth's ecosystems, biodiversity, and the global carbon cycle. Both land use change and forestry play crucial roles in climate change mitigation and adaptation strategies.</p>
            <br />
            <p>The chart below shows the trends and patterns of carbon dioxide (CO₂) emissions from various sources on a global scale over a specific period of time. It is a visual representation of the total amount of CO₂ emitted annually by different sectors and globally worldwide.</p>
            <br />
            <br />
            <Charte />
            <p><i>Source: <a href='https://www.climatewatchdata.org/data-explorer/historical-emissions?historical-emissions-data-sources=climate-watch&historical-emissions-gases=all-ghg&historical-emissions-regions=All%20Selected&historical-emissions-sectors=total-including-lucf%2Ctotal-including-lucf&page=1' target='_blank'>Climate Watch</a></i></p>
            <br />
            <hr />
            <br />
            <h1>Global Carbon Budget</h1>
            <br />
            <p>Achieving net zero emissions means that the amount of CO₂ emitted into the atmosphere is equal to the amount removed or offset, resulting in no net increase in atmospheric greenhouse gas concentrations. To combat the effects of climate change, there is a global effort to reduce CO₂ emissions to net zero.</p>
            <br />
            <p>Achieving net zero emissions is a critical goal in the fight against climate change. Many countries, organizations, and companies have set ambitious targets to reach net zero emissions by a specific date, usually around mid-century (e.g., by 2050). By achieving net zero emissions, the hope is to limit global warming and reduce the impacts of climate change on the planet and its inhabitants.</p>
            <br />
            <p>Every year of delay in action means that the emissions must reach net zero one year earlier. <a href='https://www.realclimate.org/index.php/archives/2019/08/how-much-co2-your-country-can-still-emit-in-three-simple-steps/' target='_blank'>If you don’t achieve reductions in the next ten years but keep emissions constant, and reduce linearly after that, the result is that you have to reach zero ten years earlier!</a> It can be checked in the chart below by no action taken till a particular year, we run out of carbon budget sooner if we don't take any action till 2025 (for example) than if we don't take any action till 2023. Hence, we need to take action as soon as we can to reduce the emission by some amount every year so that eventually we reach zero emission. There are different ways to model this. One way is to reduce the emission every year globally by the same amount. This approach gives us the year where we run out of carbon budget and should not emit anymore of CO₂.</p>
            <br />
            <h2>Explaining the parameters of the chart</h2>
            <br />
            <p><b>Global Warming Level: </b>The goal of limiting warming above pre-industrial levels is a central objective of global climate action to address climate change and its impacts on the planet and human societies. The international community has set ambitious targets to limit global warming, primarily focusing on limiting the rise in global average temperature to well below 2 degrees Celsius above pre-industrial levels and pursuing efforts to limit the increase to 1.5 degrees Celsius. For the estimation purpose, we provide a range from 1.3 to 2.4 degree Celsius to calculate the year we will run out of carbon budget.</p>
            <br />
            <p><b>Probability:</b> The probability or percentile expresses a likelihood that the carbon
              budget, remaining from 1 January 2020, will stay below the selected global
              warming level. Let us consider, </p>
            <br />
            <p><b>Warming Level: </b>1.3°C
              <br />
              <b>Global Carbon Emission: </b>150 GtCO₂ (Gigatons of CO₂)
              <br />
              <b>Percentile:  </b>50%
              <br />
              <br />
              What this means is that there is a 50% probability or likelihood that global temperatures will increase by 1.3°C above pre-industrial levels if the cumulative emissions of carbon dioxide (CO₂) in the atmosphere reach 150 Gigatons of CO₂ by 1st January 2020.

              In other words, the IPCC is indicating that there is a 50% chance that the world will experience a temperature increase of 1.3°C if human activities have emitted a total of 150 GtCO₂ by the specified date.

              The use of percentiles in this context is an important way to express uncertainty in climate projections. Climate science involves complex models and various factors that contribute to uncertainties in future climate outcomes. By providing probabilities in the form of percentiles, the IPCC is conveying the range of potential outcomes based on the best available scientific evidence and modeling.
            </p>
            <br />
            <h1>Net Zero Emission Year Chart</h1>
            <br />
            <p><b>Select the paramaters below</b></p>
            <br />
            <p>
              <label>
                Global Warming Level &nbsp;
                <select id="later-linear-warming" onChange={showYearsEmissionsLaterLinear} defaultValue={'1.5'}>
                  <option value="1.3">1.3ºC</option>
                  <option value="1.4">1.4ºC</option>
                  <option value="1.5">1.5ºC</option>
                  <option value="1.6">1.6ºC</option>
                  <option value="1.7">1.7ºC</option>
                  <option value="1.8">1.8ºC</option>
                  <option value="1.9">1.9ºC</option>
                  <option value="2.0">2.0ºC</option>
                  <option value="2.1">2.1ºC</option>
                  <option value="2.2">2.2ºC</option>
                  <option value="2.3">2.3ºC</option>
                  <option value="2.4">2.4ºC</option>
                </select> &nbsp;
              </label>
              <br />
              <br />
              <label>
                Probability &nbsp;
                <select id="later-linear-probability" onChange={showYearsEmissionsLaterLinear} defaultValue={'67'}>
                  <option value="17">17</option>
                  <option value="33">33</option>
                  <option value="50">50</option>
                  <option value="67">67</option>
                  <option value="83">83</option>
                </select>
              </label>
              <br />
              <br />
              <label>
                No action is taken until the year &nbsp;
                <input type="number" className="four-digit-number-input" id="later-linear-delay-until" min="2021" max="2100"
                  defaultValue="2021" onChange={showYearsEmissionsLaterLinear} />
              </label>
            </p>
            <br />
            <p><i><b>Note:</b> We assume the starting emission as 70 GtCO₂</i></p>
            <br />
            <div id="later-linear-alert" className="alert alert-danger" role="alert" hidden>
              <b>Note:</b> This scenario can't tell what the warming will likely be, only when the budget runs
              out, since net zero emissions are not reached before the budget runs out.
            </div>
            <div id="later-linear-chart-holder"></div>
            <br />
            <h2>Results</h2>
            <br />
            <p>
              <span id="later-linear-yearly-decrease"></span>.<br /> <br />
              <span id="later-linear-budget"></span>. <br /> <br />
              <span id="later-linear-total"></span>. <br /> <br />
              <span>⚫ Since entire carbon budget is used, the remaining global carbon budget will be <b>0</b>.</span> <br /> <br />
              <div className="special">
                <span id="later-linear-end-year"></span>.
              </div>
            </p>
          </div>
        </div>
      </InnerLayout>
    </DashboardStyled >
  )
}

const DashboardStyled = styled.div`
  .stats-con {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    .chart-con {
      grid-column: 1/8;
      p {
        font-size: 1.3rem;
        line-height: 1.7rem;
      }
      select, input {
        padding: 0.2rem;
        width: 100px;
        font-size: 1.1rem;
        border: thin solid lightblue;
        border-radius: 4px;
      }
      .special {
        background-color: orangered;
        text-align: center;
        padding: 0.3rem;
        font-size: 1.6rem;
      }
    }
  }
`

export default Global