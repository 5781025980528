import { useState } from 'react';
import styled from 'styled-components';
import { MainLayout } from './styles/Layouts';
import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Global from './components/Global';

function App() {

  const [active, setActive] = useState(1);
  
  const displayData = () => {
    switch (active) {
      case 1:
        return <Home />
      case 2:
        return <Global />
      case 3:
        return <Dashboard />
      default:
        return <Home />
    }
  }

  return (
    <AppStyled className="App">
      <MainLayout>
        <Navigation active={active} setActive={setActive}></Navigation>
        <main>
          {displayData()}
        </main>
      </MainLayout>
    </AppStyled>
  );
}

const AppStyled = styled.div`
  overflow-x: hidden;
  height: 100vh;
  background: lightblue;
  position: relative;
  main {
    padding: 1rem 1rem;
    z-index: 1;
    flex: 1;
    background: rgba(252, 246, 249, 0.78);
    border: 3px solid #ffffff;
    border-radius: 32px;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`

export default App;
